import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { isAuthenticated } from './services/auth';

import Error404 from './pages/error404';
import UserLogin from './pages/user/login';

import Dash from './pages/dashboard/index';
import Balance from './pages/balance/index';
import Payee from './pages/payee/index';
import RequestWithDraw from './pages/requestWithDraw/index';
import Users from './pages/user/users';
import UserEdit from './pages/user/edit';
import ResetPass from './pages/user/resetPass';
import UserCreate from './pages/user/create';
import BankAccounts from './pages/bankAccount/index';
import BankAccountEdit from './pages/bankAccount/edit';
import BankAccountsCreate from './pages/bankAccount/create';
import InvoiceReleased from './pages/invoiceReleased/index';
import InvoicesReportAdminByDate from "./pages/invoices/reportAdminByDate";
import TransfersWithdrawalDay from './pages/transfersWithdrawalDay/index';
import Transactions from './pages/transactions';
import ShipmentWithdrawals from './pages/shipmentWithdrawals/index';
import platformTrigger from './pages/platformTrigger/index';
import RequestTransfer from './pages/requestTransfer/index';
import BalanceMajorCompany from './pages/balanceMajorCompany/index';
import FinancialReturn from './pages/financialReturn/index';
import FinancialReturnCreate from './pages/financialReturn/create';
import FinancialReturnEdit from './pages/financialReturn/edit';
import RequestWithDrawByTaxes from './pages/requestWithDrawByTaxes/index';
import InvoiceRanking from './pages/invoiceRanking/index';
import RequestWithDrawByTaxesList from './pages/requestWithDrawList';
import BankDayOff from './pages/bankDayOff';
import BankDayOffCreate from './pages/bankDayOff/create';
import CheckInvoice from './pages/checkInvoice';
import CheckPix from './pages/checkPix';
import InvoicePaidByDayAndRates from './pages/invoicePaidByDayAndRates';
import CheckInvoiceByLineDigitable from './pages/checkInvoiceByLineDigitable';
import ListWithdrawalBatch from './pages/withdrawalBatch/List';
import ListSentWithdrawalBatch from './pages/withdrawalBatch/ListSent';
import ManualBatchDelivery from './pages/manualBatchDelivery/index';
import SearchBatchInformation from './pages/searchBatchInformation/index';
import SearchPixInformation from './pages/searchPixInformation/index';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={ props =>
            isAuthenticated() ? (
                <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/", state: {from: props.location } }} />
                )   
        }
    />
);

function Routes() {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={UserLogin} />
                <PrivateRoute path="/dashboard" component={Dash} />
                <PrivateRoute path="/payee" component={Payee} />
                <PrivateRoute path="/balance" component={Balance} />
                <PrivateRoute path="/request_withdraw" component={RequestWithDraw} />
                <PrivateRoute path="/users" component={Users} />
                <PrivateRoute path="/user" exact component={UserCreate} />
                <PrivateRoute path="/user/edit/:id" exact component={UserEdit} />
                <PrivateRoute path="/user/resetPass/:id" exact component={ResetPass} />
                <PrivateRoute path="/bank_accounts" exact component={BankAccounts} />
                <PrivateRoute path="/bank_account" exact component={BankAccountsCreate} />
                <PrivateRoute path="/bank_account/edit/:id" exact component={BankAccountEdit} />
                <PrivateRoute path="/invoice_released" component={InvoiceReleased} />
                <PrivateRoute path="/invoicesReportAdmin" component={InvoicesReportAdminByDate} />
                <PrivateRoute path="/transfers_withdrawal_day" component={TransfersWithdrawalDay} />
                <PrivateRoute path="/transactions" component={Transactions} />
                <PrivateRoute path="/shipment_withdrawals" component={ShipmentWithdrawals} />
                <PrivateRoute path="/platform_trigger" component={platformTrigger} />
                <PrivateRoute path="/request_transfer" component={RequestTransfer} />
                <PrivateRoute path="/balance_major_company" component={BalanceMajorCompany} />
                <PrivateRoute path="/financial_returns" component={FinancialReturn} />
                <PrivateRoute path="/financial_return" component={FinancialReturnCreate} />
                <PrivateRoute path="/financial_return_edit/:id" component={FinancialReturnEdit} />
                <PrivateRoute path="/transfers_withdrawal_taxes" component={RequestWithDrawByTaxes} />
                <PrivateRoute path="/invoices_ranking" component={InvoiceRanking} />
                <PrivateRoute path="/transfers_withdrawal_list" component={RequestWithDrawByTaxesList} />
                <PrivateRoute path="/list_withdrawal_batch" component={ListWithdrawalBatch} />
                <PrivateRoute path="/list_batch_payments_sent" component={ListSentWithdrawalBatch} />
                <PrivateRoute path="/bank_dayoff" component={BankDayOff} />
                <PrivateRoute path="/bank_dayoff_create" component={BankDayOffCreate} />
                <PrivateRoute path="/check_invoice" component={CheckInvoice} />
                <PrivateRoute path="/check_pix" component={CheckPix} />
                <PrivateRoute path="/invoice_paid_by_day_rates" component={InvoicePaidByDayAndRates} />
                <PrivateRoute path="/check_invoice_by_linedigitable" component={CheckInvoiceByLineDigitable} />
                <PrivateRoute path="/manual_batch_delivery" component={ManualBatchDelivery} />
                <PrivateRoute path="/search_batch_information" component={SearchBatchInformation} />
                <PrivateRoute path="/search_pix_information" component={SearchPixInformation} />
                
                <Route path="*" component={Error404} />
            </Switch>
        </BrowserRouter>
    );
}

export default Routes;
